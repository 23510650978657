var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"program-add-wrapper"},[_c('b-modal',{ref:'add-program-'+_vm.indexref,attrs:{"id":'add-program-'+_vm.indexref,"centered":"","hide-footer":"","size":"lg","title":"Programmi"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('b-card',[_c('validation-observer',{ref:"ProgramRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.saveProgram())}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ragione Sociale")]),_c('validation-provider',{attrs:{"rules":"required","name":"CardName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Ragione Sociale"},model:{value:(_vm.Program.program),callback:function ($$v) {_vm.$set(_vm.Program, "program", $$v)},expression:"Program.program"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Referente")]),_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Referente"},model:{value:(_vm.Program.name),callback:function ($$v) {_vm.$set(_vm.Program, "name", $$v)},expression:"Program.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("E-Mail")]),_c('validation-provider',{attrs:{"rules":"required","name":"Mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Mail"},model:{value:(_vm.Program.programEmail),callback:function ($$v) {_vm.$set(_vm.Program, "programEmail", $$v)},expression:"Program.programEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Contatto")]),_c('validation-provider',{attrs:{"rules":"required","name":"contact"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Contatto"},model:{value:(_vm.Program.contact),callback:function ($$v) {_vm.$set(_vm.Program, "contact", $$v)},expression:"Program.contact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Indirizzo","label-for":"fh-desc"}},[_c('b-form-textarea',{attrs:{"id":"fh-desc","placeholder":"Descrizione","rows":"3","max-rows":"8"},model:{value:(_vm.Program.address),callback:function ($$v) {_vm.$set(_vm.Program, "address", $$v)},expression:"Program.address"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Stato","label-for":"country"}},[_c('v-select',{staticClass:"fixborder",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":true,"options":_vm.countries,"label":"Nome","index":"code","reduce":function (val) { return val.code; },"input-id":"country"},model:{value:(_vm.Program.country),callback:function ($$v) {_vm.$set(_vm.Program, "country", $$v)},expression:"Program.country"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s((_vm.Program.Id)? "Salva" : "Aggiungi")+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":_vm.reset}},[_vm._v(" Reset ")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }