import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchProgramsByDealer(ctx = null, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/program/dealer', { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPrograms(ctx = null, queryParams = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/program/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProgram(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/program/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AddUpdateProgram(ctx, program) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/program/addupdate', { obj: program })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/program/delete', { Id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    enable(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/program/enable', { Id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMarca(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/veicoli/marca`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSeries(ctx, marca) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/wsdlr/veicoli/marca/series`, { marca }) //	 .get(`/wsdlr/veicoli/marca/${marca}/series`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVeicoliModel(ctx, { marca, serie }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/wsdlr/veicoli/marca/serie/`, { marca, serie }) // .get(`/wsdlr/veicoli/marca/${marca}/serie/${serie}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
