<template>

  <!-- Table Container Card -->
  <b-card
    title="Programmi"
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <label>Entries</label> -->
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            to="programs/new"
          >
            Nuovo
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Cerca..."
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refProgramListTable"
      :items="programList"
      responsive
      :fields="tableColumns"
      primary-key="Id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(Programma)>
        <feather-icon
          icon="TargetIcon"
          class="mx-auto"
        /> Programma
      </template>
      <template #head(stato)>
        <feather-icon
          icon="CheckIcon"
          class="mx-auto"
        /> Abilitato
      </template>

      <template #head(actions)>
        <feather-icon
          icon="ZapIcon"
          class="mx-auto"
        /> Azioni
      </template>

      <!-- Column: Id -->
      <template #cell(Id)="data">
        <b-link
          :to="{ name: 'edit-program', params: { id: data.item.Id }}"
          class="font-weight-bold"
        >
          #{{ data.item.Id }}
        </b-link>
      </template>

      <!-- Column: Name -->
      <template #cell(Programma)="data">
        <b-link
          :to="{ name: 'edit-program', params: { id: data.item.Id }}"
          class="font-weight-bold"
        >
          {{ data.item.Name }}

        </b-link>
      </template>
      <!-- Column: Stato -->
      <template #cell(stato)="data">
        <b-form-checkbox
          v-model="data.item.IsEnabled"
          input-id="is-enabled-product"
          switch
          vertical-align="center"
          disabled
          value="Y"
          unchecked-value="N"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div

          class="text-nowrap"
        >

          <!-- <b-link
            :to="{ name: 'edit-program', params: { id: data.item.Id }}"
          >

            <feather-icon
              :id="`program-row-${data.item.Id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
            />
            <b-tooltip
              title="Modifica"
              :target="`program-row-${data.item.Id}-edit-icon`"
            />
          </b-link> -->

          <feather-icon
            v-if="data.item.IsEnabled == 'Y'"
            :id="`program-row-${data.item.Id}-delete-icon`"
            icon="XIcon"
            size="16"
            class="mx-1"
            @click="confirmDeleteElementList(data.item)"
          />
          <b-tooltip
            v-if="data.item.IsEnabled == 'Y'"
            title="Disabilita"
            :target="`program-row-${data.item.Id}-delete-icon`"
          />
          <feather-icon
            v-if="data.item.IsEnabled == 'N'"
            :id="`program-row-${data.item.Id}-enable-icon`"
            icon="CheckIcon"
            size="16"
            class="mx-1"
            @click="confirmEnablelementList(data.item)"
          />
          <b-tooltip
            v-if="data.item.IsEnabled == 'N'"
            title="Abilita"
            :target="`program-row-${data.item.Id}-enable-icon`"
          />

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalElements"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          />

        </b-col>

      </b-row>
    </div>
    <program-add-modal
      indexref="-1"
    />
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BFormCheckbox,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import store from '@/store'
import {
  onUnmounted, watch, onMounted, ref, computed,
} from '@vue/composition-api'
import ProgramAddModal from './ProgramAddModal.vue'
import useProgramList from './useProgramList'
import programStoreModule from './programStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BFormCheckbox,
    BLink,
    BPagination,
    BTooltip,

    vSelect,
    ProgramAddModal,
  },
  data() {
    return {
      // programList: [],
      // currentPage: 1,
      // totalPrograms: 10,
      // perPageOptions: [10],
      tableColumns: ['Programma', 'Stato', "Actions"],
      // perPage: 10,
      // searchQuery: "",
      // sortBy: "",
      // isSortDirDesc: false,
      // dataMeta: {},

    }
  },
  methods: {
    confirmEnablelementList(item) {
      this.$swal({
        title: 'Attenzione!',
        text: `Sicuro di volere ABILITARE il programma: ${item.Name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, ABILITA',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        async preConfirm() {
          try {
            console.log(await programStoreModule.actions.enable(null, item.Id))
          } catch (err) {
            console.log(err)
            return null
          }
        },

      }).then(result => {
        console.log(result)
        if (result.value) {
          this.fetchData()
          this.$swal({
            icon: 'success',
            title: 'Abilitato!',
            text: 'Il programma è stato abilitato',
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Errore!',
            text: 'Si è verificato un errore. Programma non abilitato',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      }).catch(err => {
        this.$swal({
          title: 'Errore!',
          text: 'Si è verificato un errore. Programma non eliminato',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    confirmDeleteElementList(item) {
      this.$swal({
        title: 'Attenzione!',
        text: `Sicuro di volere disabilitare il programma: ${item.Name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, disabilita',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        async preConfirm() {
          try {
            console.log(await programStoreModule.actions.delete(null, item.Id))
          } catch (err) {
            console.log(err)
            return null
          }
        },

      }).then(result => {
        console.log(result)
        if (result.value) {
          this.fetchData()
          this.$swal({
            icon: 'success',
            title: 'Eliminato!',
            text: 'Il programma è stato disabilitato',
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Errore!',
            text: 'Si è verificato un errore. Programma non eliminato',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      }).catch(err => {
        this.$swal({
          title: 'Errore!',
          text: 'Si è verificato un errore. Programma non eliminato',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
  },
  setup() {
    const COMPANY_DEALER_STORE_MODULE_NAME = 'app-program'

    // Register module
    if (!store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.registerModule(COMPANY_DEALER_STORE_MODULE_NAME, programStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_DEALER_STORE_MODULE_NAME)
    })

    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('')
    const isSortDirDesc = ref(false)
    const totalElements = ref(0)
    const programList = ref([])

    const dataMeta = computed(() => {
      const localItemsCount = programList.value ? programList.value.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalElements.value,
      }
    })

    const fetchData = () => {
      programStoreModule.actions.fetchPrograms(null, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      }).then(resp => {
        console.log("resp.data", resp.data)
        programList.value = resp.data.program
        totalElements.value = resp.data.total
      }).catch(eer => {
        console.log("EEEEE", eer)
      })
    }
    watch([currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
      fetchData()
    })
    onMounted(() => {
      fetchData()
    })
    return {
      currentPage, perPageOptions, searchQuery, sortBy, perPage, isSortDirDesc, fetchData, programList, dataMeta, totalElements,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .program-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

